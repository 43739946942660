import {
  getPlatformURL,
  isStripeDomain,
} from '@stripe-internal/connect-embedded-lib';
import {InitOptions} from './ConnectJSInterface/InitAndUpdateOptionsTypes';
import {transformInitOptions} from './ConnectJSInterface/transformOptions';
import {validateInitOptions} from './ConnectJSInterface/validateOptions';

// The init options override are used to simplify overriding options in connect elements, like
// locale and primary color
export const initOptionsOverrideQueryParamName = 'initOptionsOverride';
export const calculateInitOptionsOverride = (
  windowUrl: URL = new URL(window.location.href),
): Partial<InitOptions> | undefined => {
  if (!isStripeDomain(windowUrl)) {
    return undefined;
  }

  const optionsOverride = new URL(windowUrl).searchParams.get(
    initOptionsOverrideQueryParamName,
  );

  if (!optionsOverride) {
    return undefined;
  }

  // We only apply these options they are valid
  const parsedOptionsOverride = JSON.parse(optionsOverride);
  const transformedOptions = transformInitOptions(parsedOptionsOverride);
  try {
    validateInitOptions(transformedOptions, false, true);
    return transformedOptions;
  } catch (error) {
    // We can't access the testmode logger here as we don't know the livemode yet. this should only happen in dev/tests/allowed domains
    // eslint-disable-next-line no-console
    console.error(
      'Failed to parse init options override. Defaulting to no overrides.',
      error,
    );
    return undefined;
  }
};

const initOptionsOverride = calculateInitOptionsOverride();

/**
 * Calculates a URL with the init options override applied.
 */
export function getInitOptionsOverride(): Partial<InitOptions> | undefined {
  return initOptionsOverride;
}
export const buildInitOptionsUrl = (
  initOptions: Partial<InitOptions>,
): string => {
  const currentUrl = new URL(getPlatformURL());

  const serializedOptionsOverride = JSON.stringify(initOptions);
  currentUrl.searchParams.set(
    initOptionsOverrideQueryParamName,
    serializedOptionsOverride,
  );

  return currentUrl.toString();
};
