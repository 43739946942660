import {getStripeOrGraphQLErrorMetadata} from '@stripe-internal/connect-embedded-lib';
// We load StripeJS without side effects so we can control when the StripeJS script
// is injected onto the page. We want to avoid injecting the script for any platform that doesn't strictly need it
// https://github.com/stripe/stripe-js#importing-loadstripe-without-side-effects
import {loadStripe} from '@stripe/stripe-js/pure';
import type {IAnalytics, IReports, Tags} from '@sail/observability';
import {AccountSessionClaim} from '../../data-layer-frame/types';

export const includeStripeJSIfRequired = async (
  accountSessionClaim: AccountSessionClaim,
  analytics: IAnalytics,
  reports: IReports,
  publishableKey: string,
): Promise<void> => {
  // We only include stripejs on the page if stripe owns loss liability
  if (!accountSessionClaim.stripe_owns_loss_liability) {
    return;
  }

  try {
    await loadStripe(publishableKey);
  } catch (error) {
    // Report an error only - we don't throw if this fails
    const stripeErrorMetadata = getStripeOrGraphQLErrorMetadata(error);
    analytics.track('submerchant_surfaces_include-stripe-js', {
      api_result: 'error',
      ...stripeErrorMetadata,
    });
    reports.error(error, {
      tags: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(stripeErrorMetadata as Tags),
        type: 'apiError',
      },
    });
  }
};
