import {ConnectElementConfig} from '../ConnectElementList';

const hostedDashboardPreviewParams = ['icon', 'display-name'] as const;

type HostedDashboardPreviewParams =
  (typeof hostedDashboardPreviewParams)[number];
export type HostedDashboardPreviewHtmlParams = Partial<
  Record<HostedDashboardPreviewParams, string>
>;

export const HostedDashboardPreviewConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "hostedDashboardPreview" */ '../../../components/HostedDashboardPreview/HostedDashboardPreview'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  teamOwner: 'accounts_ui',
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    icon: {
      isInternal: true,
    },
    'display-name': {
      isInternal: true,
    },
  },
  description:
    'A mini-preview of the hosted dashboard. Used in the hosted dashboard configuration page of the standard dashboard.',
};
