import {FontStyle} from '@stripe-internal/connect-embedded-lib';
import {validateSafeStringWithRegexp} from '@stripe-internal/embedded-theming';

/**
 * The validations here are referenced from StripeJS
 * See https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/c5ac1ad7/src/elements/inner/shared/safeTypes.ts
 */

export const FONT_DISPLAY: Record<FontDisplay, null> = {
  auto: null,
  block: null,
  fallback: null,
  optional: null,
  swap: null,
};
export const validateFontDisplay = (
  value: unknown,
  path: string,
): asserts value is FontDisplay => {
  if (typeof value !== 'string') {
    throw new Error(`${path} must be a string`);
  }

  if (!Object.keys(FONT_DISPLAY).includes(value)) {
    throw new Error(
      `${path} must be one of ${Object.keys(FONT_DISPLAY).join(', ')}`,
    );
  }
};

export const FONT_STYLES: Record<FontStyle, null> = {
  normal: null,
  italic: null,
  oblique: null,
};
export const validateFontStyle = (
  value: unknown,
  path: string,
): asserts value is FontStyle => {
  if (typeof value !== 'string') {
    throw new Error(`${path} must be a string`);
  }

  if (!Object.keys(FONT_STYLES).includes(value)) {
    throw new Error(
      `${path} must be one of ${Object.keys(FONT_STYLES).join(', ')}`,
    );
  }
};

// Allowed values look like U+26, U+0-7F, U+0025-00FF, U+4??, U+0025-00FF, U+4??.
export const validateFontUnicodeRange = (
  value: unknown,
  path: string,
): asserts value is string =>
  validateSafeStringWithRegexp(/^[-U+A-Fa-f0-9?, ]*$/, value, path);

export const validateFontWeight = (
  value: unknown,
  path: string,
): asserts value is string =>
  validateSafeStringWithRegexp(/^[a-zA-Z0-9-]*$/, value, path);

// Too many possible values to list. Allowed values can contain letters, numbers, spaces, - and ().
export const validateFontVariant = (
  value: unknown,
  path: string,
): asserts value is string =>
  validateSafeStringWithRegexp(
    /^([a-zA-Z0-9-]*(\([, a-zA-Z0-9-]*\))?\s?)*$/,
    value,
    path,
  );

export const validateFontStretch = (
  value: unknown,
  path: string,
): asserts value is string =>
  validateSafeStringWithRegexp(/^([a-zA-Z-]+|\d{1,3}%)$/, value, path);
