import {DeferredPromise} from '../../utils/DeferredPromise';
import {getDevLogger} from '../../utils/getLogger';

const devLogger = getDevLogger();

export type MessageDirection = 'to' | 'from';

type BaseMessage = {
  direction: MessageDirection;
  requestType: string;
  type: string;
  values?: Record<string, any> | string | undefined;
};

type MessagePortListener<MessageMap extends BaseMessage> = {
  messageDirection: MessageDirection;
  messageType: string;
  messageHandlers: {
    [K in MessageMap['requestType']]: (
      data: Extract<MessageMap, {requestType: K}>['values'],
    ) => void | Promise<void>;
  };
};

export const isMessageEventType = <MessageMap extends BaseMessage>(
  message: any,
  expectedMessageDirection: MessageDirection,
  expectedMessageType: string,
): message is MessageMap => {
  return !!(
    message &&
    typeof message === 'object' &&
    message.type &&
    message.type === expectedMessageType &&
    message.requestType &&
    typeof message.requestType === 'string' &&
    message.direction &&
    message.direction === expectedMessageDirection
  );
};

export const setupMessagePortListener = async <MessageMap extends BaseMessage>({
  event,
  messageDirection,
  messageType,
  messageHandlers,
}: MessagePortListener<MessageMap> & {
  event: MessageEvent<any>;
}) => {
  if (
    !isMessageEventType<MessageMap>(event.data, messageDirection, messageType)
  ) {
    return;
  }

  const {data} = event;
  const {requestType} = data;
  if (requestType in messageHandlers) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    messageHandlers[requestType as keyof typeof messageHandlers](data.values);
  } else {
    devLogger.error('Unknown message type: ', data.requestType);
  }
};

export type MessagePortValues = {
  port: MessagePort;
  origin: string;
};
export const setupMessagePortHandoff = <InitMessage extends BaseMessage>({
  messageReceiverDirection,
  messageReceiverType,
  messageReceiverInitRequestType,
  messageListener,
  messageReceivedCallback,
  acceptedOrigin,
}: {
  messageReceiverDirection: InitMessage['direction'];
  messageReceiverType: InitMessage['type'];
  messageReceiverInitRequestType: InitMessage['requestType'];
  messageListener?: (
    port: MessagePort,
  ) => (event: MessageEvent) => Promise<void>;
  messageReceivedCallback?: (
    portValues: MessagePortValues,
    initValues: InitMessage['values'],
  ) => void;
  acceptedOrigin?: string;
}): (() => [Promise<MessagePortValues>, Promise<InitMessage['values']>]) => {
  let portInitialized = false;
  const deferredPort = new DeferredPromise<{
    port: MessagePort;
    origin: string;
  }>();
  const deferredInit = new DeferredPromise<InitMessage['values']>();

  return () => {
    window.addEventListener('message', (event) => {
      if (
        !isMessageEventType<InitMessage>(
          event.data,
          messageReceiverDirection,
          messageReceiverType,
        )
      ) {
        return;
      }

      const {data} = event;

      if (data.requestType !== messageReceiverInitRequestType) {
        devLogger.error('Unexpected message received', data.requestType);
        return;
      }

      if (portInitialized) {
        devLogger.error('The message port has already been handed off.');
        return;
      }

      if (event.ports.length !== 1) {
        devLogger.error('Did not receive a reply port in init message.');
        return;
      }

      if (acceptedOrigin && event.origin !== acceptedOrigin) {
        devLogger.error(
          `Init message received from unexpected origin ${event.origin}, expected ${acceptedOrigin}`,
        );
        return;
      }

      const [port] = event.ports;

      if (messageListener) {
        port.onmessage = messageListener(port);
      }
      portInitialized = true;

      const messagePortValues: MessagePortValues = {
        port,
        origin: event.origin,
      };
      deferredPort.resolve(messagePortValues);
      deferredInit.resolve(data.values);
      if (messageReceivedCallback) {
        messageReceivedCallback(messagePortValues, data.values);
      }
    });

    return [deferredPort.promise, deferredInit.promise];
  };
};
