import {isDevEnvironment} from '../isDevEnvironment';
import {BaseMetricGauges} from './PerformanceMetricEvents';
import {getCurrentScriptUrlContext} from '../getCurrentScriptUrlContext';

const CONNECT_JS = 'connect.js';
// Time in ms to wait for future script load events before sending Initialization metrics
// TODO: Optimize this to fire metrics before the page is closed (ie. onChange event listener and Navigator.sendBeacon())
const NETWORK_IDLE_PERIOD = 5000;

type performanceEntry = {
  transferSize: number;
  duration: number;
  start: number;
  end: number;
};

// Timing marks are manually captured instead of using Performance.mark() to avoid
// impacting Platform use of the Performance Timing API (https://developer.mozilla.org/en-US/docs/Web/API/Performance/resourcetimingbufferfull_event)
type ConnectTimingMark =
  | 'initStart'
  | 'connectGlobalReady'
  | 'frameReady'
  | 'authEnd';

const connectTimingMarks: Partial<Record<ConnectTimingMark, number>> = {};
export const markConnectTiming = (mark: ConnectTimingMark) => {
  if (!connectTimingMarks[mark]) {
    // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
    connectTimingMarks[mark] = window.performance?.now();
  }
};

export const getConnectJsAssetPerformanceEntries = (
  resourceTimings: PerformanceResourceTiming[],
): Record<string, performanceEntry> => {
  const CURRENT_SCRIPT_URL = getCurrentScriptUrlContext().origin;
  const EXPECTED_HOST_ORIGIN = isDevEnvironment
    ? // We need to undo the origin swap that we do in getCurrentScriptUrlContext
      CURRENT_SCRIPT_URL.replace('127.0.0.1', 'localhost')
    : CURRENT_SCRIPT_URL;

  return resourceTimings.reduce<Record<string, performanceEntry>>(
    (connectJsAssets, currentEntry) => {
      // Only report our own js resources
      const assetMatch =
        currentEntry.name.startsWith(EXPECTED_HOST_ORIGIN) &&
        currentEntry.name.endsWith('.js');

      if (assetMatch) {
        const assetFilename = currentEntry.name.split('/').at(-1) ?? '';
        return {
          ...connectJsAssets,
          [assetFilename]: {
            transferSize: currentEntry.transferSize,
            duration: Math.ceil(currentEntry.duration),
            start: Math.ceil(currentEntry.startTime),
            end: Math.ceil(currentEntry.responseEnd),
          },
        };
      }
      return connectJsAssets;
    },
    {},
  );
};

let connectJsLoadStartTime: number | undefined;
export const getConnectJsLoadStartTime = () => {
  if (connectJsLoadStartTime) return connectJsLoadStartTime;
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  if (!window.performance?.getEntriesByType) return undefined;
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals, @typescript-eslint/consistent-type-assertions
  const res = window.performance.getEntriesByType(
    'resource',
  ) as PerformanceResourceTiming[];

  const connectJsTimings = res.find((entry) => {
    return entry.name.split('/').at(-1) === CONNECT_JS;
  });
  connectJsLoadStartTime = connectJsTimings?.startTime;
  return connectJsLoadStartTime;
};

export const getAssetSizeAndLoadDurationFromEntries = (
  connectJsAssetMetrics: Record<string, performanceEntry>,
) => {
  return Object.values(connectJsAssetMetrics).reduce(
    (acc, currentEntry) => {
      const {assetLoadStart, assetLoadEnd, totalAssetSize} = acc;
      return {
        assetLoadStart: Math.min(assetLoadStart, currentEntry.start),
        assetLoadEnd: Math.max(assetLoadEnd, currentEntry.end),
        totalAssetSize: totalAssetSize + currentEntry.transferSize,
      };
    },
    {assetLoadStart: Infinity, assetLoadEnd: 0, totalAssetSize: 0},
  );
};

export const getBaseTimingMetrics = (): Partial<
  Record<BaseMetricGauges, number>
> | null => {
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  if (window.performance?.getEntriesByType) {
    // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals, @typescript-eslint/consistent-type-assertions
    const res = window.performance.getEntriesByType(
      'resource',
    ) as PerformanceResourceTiming[];

    const connectJsAssetMetrics = getConnectJsAssetPerformanceEntries(res);
    const connectBaseEntry = connectJsAssetMetrics[CONNECT_JS];

    // Performance Metrics can be cleared by the Platform so check that our metrics are present
    if (connectBaseEntry) {
      const assetLoadStart = connectBaseEntry.start;

      const {assetLoadEnd, totalAssetSize} =
        getAssetSizeAndLoadDurationFromEntries(connectJsAssetMetrics);

      const {initStart, frameReady, authEnd, connectGlobalReady} =
        connectTimingMarks;

      const initToFrameReady =
        initStart && frameReady ? Math.ceil(frameReady - initStart) : undefined;

      const initToAuthUserDuration =
        initStart && authEnd ? Math.ceil(authEnd - initStart) : undefined;

      const connectLoadStartToAuthUserDuration = initToAuthUserDuration
        ? connectBaseEntry.duration + initToAuthUserDuration
        : undefined;

      return {
        js_total_network_duration: assetLoadEnd - assetLoadStart,
        js_total_network_transfer_size: totalAssetSize,
        connect_js_network_duration: connectBaseEntry.duration,
        connect_js_network_transfer_size: connectBaseEntry.transferSize,
        connect_js_load_to_connect_global_ready: connectGlobalReady
          ? Math.ceil(connectGlobalReady - connectBaseEntry.start)
          : undefined,
        // Only include these values if they are not undefined
        ...(initToAuthUserDuration && {
          init_to_auth_ready: initToAuthUserDuration,
          js_load_to_auth_ready: connectLoadStartToAuthUserDuration,
        }),
        ...(initToFrameReady && {init_to_frame_ready: initToFrameReady}),
      };
    }

    return null;
  }

  return null;
};

export const onNetworkIdle = (onIdleCallback: (...arr: any[]) => void) => {
  let idleTimer: number;

  try {
    const observer = new PerformanceObserver((items) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (items.getEntries() as PerformanceResourceTiming[])
        .filter(({initiatorType}) => initiatorType === 'script')
        .forEach(() => {
          if (idleTimer) {
            clearTimeout(idleTimer);
          }
          // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
          idleTimer = window.setTimeout(() => {
            // Stop monitoring resource timings
            observer.disconnect();
            onIdleCallback();
          }, NETWORK_IDLE_PERIOD);
        });
    });

    observer.observe({
      type: 'resource',
      buffered: true,
    });
  } catch (e) {
    // Do nothing if the browser doesn't support the Performance Observer API.
  }
};
