import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const CapitalFinancingPromotionConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "capitalFinancingPromotionExperience" */
      '../../../components/Capital/CapitalFinancingPromotion'
    ),
  enabledKey: 'capital_financing_promotion',
  enabledOverrideFlag: ComponentOverrideFlag.capital_financing_promotion,
  htmlEvents: ['onapplicationsubmitted'],
  customHtmlElementMethods: {
    setOnApplicationSubmitted: {
      type: 'EventListenerMethod',
      event: 'onapplicationsubmitted',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function for when a financing application is submitted.',
    },
  },
  releaseState: 'private_beta',
  teamOwner: 'capital_for_platforms',
  description:
    'Links to the Capital offer application when the user has a financing offer and is invisible otherwise.',
};
