import type {Flag} from '@sail/access';
import {ConnectElementConfigEnabledKeyExcludingInternal} from '../../connect/ConnectJSInterface/ConnectElementList';

export const ComponentOverrideFlag: Record<
  ConnectElementConfigEnabledKeyExcludingInternal,
  Flag
> = {
  account_management: 'enable_account_management_component' as const,
  account_onboarding: 'enable_account_onboarding_component' as const,
  app_onboarding: 'enable_app_onboarding_component' as const,
  app_install: 'enable_app_install_component' as const,
  app_viewport: 'enable_app_viewport_component' as const,
  balances: 'enable_balances_component' as const,
  capital_financing: 'enable_capital_financing_component' as const,
  capital_financing_application:
    'enable_capital_financing_application_component' as const,
  capital_financing_promotion:
    'enable_capital_financing_promotion_component' as const,
  capital_overview: 'enable_capital_overview_component' as const,
  documents: 'enable_documents_component' as const,
  instant_payouts: 'enable_instant_payouts_component' as const,
  issuing_card: 'enable_issuing_card_component' as const,
  issuing_cards_list: 'enable_issuing_cards_list_component' as const,
  issuing_cardholder_create:
    'enable_issuing_cardholder_create_component' as const,
  notification_banner: 'enable_notification_banner_component' as const,
  payment_details: 'enable_payment_details_component' as const,
  payment_method_settings: 'enable_payment_method_settings_component' as const,
  payments: 'enable_payments_component' as const,
  payouts: 'enable_payouts_component' as const,
  payouts_list: 'enable_payouts_list_component' as const,
  tax_registrations: 'enable_tax_registrations_component' as const,
  tax_settings: 'enable_tax_settings_component' as const,
  transactions_list: 'enable_transactions_list_component' as const,
  financial_account: 'enable_financial_account_component' as const,
  financial_account_transactions:
    'enable_financial_account_transactions_component' as const,
  // TODO(@stevekaliski): Deprecate
  treasury_financial_account:
    'enable_treasury_financial_account_component' as const,
};

// These are client-side flags received from changing the URL query params like so ?enable_developer_ids=true
// Flags in this list cannot be updated via go/flags and are meant only for testing purposes, so they aren't meant to be temporary
// These should be kept in sync with src/access.tsx if they are debug only.
export const URL_FLAGS = {
  ENABLE_LIST_VIEW_CURRENCY_FILTER: 'enable_list_view_currency_filter' as const, // Enables the currency filter in the list view
  ENABLE_DEVELOPER_IDS: 'enable_developer_ids' as const, // Show ID of Stripe objects, e.g. ch_, pi_, etc.
  SHOW_ARK_DEBUG_TOOLS: 'show_ark_debug_tools' as const, // Controls whether ARK's debugging tools are turned on for onboarding.
  ENABLE_UICONFIG_COPY_LINK: 'enable_uiconfig_copy_link' as const, // Enables copy link button in ui config component
  ENABLE_THEMING_SHORT_NAMES: 'enable_theming_short_names' as const, // Enables shorter labels for theming
  STRIPE_INTERNAL_OVERRIDE_FLAGS_IN_EXTERNAL_DOMAINS:
    'stripe_internal_override_flags_in_external_domains' as const, // Enables retrieving fresh account link on reload
  ENABLE_SUS_DUAL_REPORTING: 'enable_sus_dual_reporting' as const, // Enables double reporting for metrics/analytics, etc. (legacy vs new path)
  ENABLE_SESSIONS_DEMO: 'enable_sessions_demo' as const, // Hides testmode banners and rename testmode bank accounts.
  ENABLE_BOTTOM_SHEET_OVERLAYS: 'enable_bottom_sheet_overlays' as const, // Enables bottom sheet overlays for testing on touchscreens
};

export const SERVER_CONTROLLED_FLAGS = {
  // Server side controlled behavior: these control whether some features work for certain account configurations
  ENABLE_CARD_ART_IN_ISSUING_EMBEDDED_COMPONENTS:
    'enable_card_art_in_issuing_embedded_components' as const,
  ENABLE_PHYSICAL_CARD_BUNDLES_IN_ISSUING_EMBEDDED_COMPONENTS:
    'enable_physical_card_bundles_in_issuing_embedded_components' as const,
  ENABLE_ISSUING_API: 'enable_issuing_api' as const,
  ENABLE_TREASURY_API: 'enable_treasury_api' as const,
  ENABLE_TREASURY_TESTMODE: 'enable_treasury_testmode' as const,
  ENABLE_TAX_FORMS_SECTION_SUS: 'enable_tax_forms_section_sus' as const,
  INSTANT_PAYOUTS_RTP_DISABLED: 'instant_payouts_rtp_disabled' as const, // disables Instant Payouts to US Banks via RTP and displays outage banner

  // Flags - these are temporary and are used for feature enablement
  ENABLE_TOPUPS: 'enable_topups' as const,
  ENABLE_ADD_LINKED_ACCOUNTS: 'enable_add_linked_accounts' as const, // Enables the functionality to add linked accounts in account management
  ARKV2_ALLOW_ALL_CBP_COUNTRIES: 'arkv2_allow_all_cbp_countries' as const, // Enables onboarding Express accounts in any country
  ENABLE_DEBIT_CARD_ENTRY: 'enable_debit_card_entry' as const, // Enables user to select debit card as an external account
  BANK_CONNECTIONS_IN_ONBOARDING_UNIFIED:
    'bank_connections_in_onboarding_unified' as const, // should we show bank connections in the external account composition in onboarding?
  PLATFORM_EXTERNAL_ACCOUNT_SETTINGS_ENABLED:
    'platform_external_account_settings_enabled' as const, // uses Platform External Account Settings (PEAS) for LEAF settings
  ENABLE_SPENDING_CONTROLS_UI_IN_ISSUING_CARDS_COMPONENTS:
    'enable_spending_controls_ui_in_issuing_cards_components' as const,
  BANK_CONNECTIONS_DISABLED_ON_EXPRESS_DASHBOARD:
    'bank_connections_disabled_on_express_dashboard' as const,
  ENABLE_BANK_ACCOUNT_VERIFICATION_ALTERNATIVE_METHODS:
    'enable_bank_account_verification_alternative_methods' as const, // Enable alternative methods (FC, re-key) in the UI for resolving Bank Acocunt Verification
  BAV_ENABLE_FC_OVERRIDE_PEAS: 'bav_enable_fc_override_peas' as const, // Enable Financial Connections in the Bank Account Verification flow, even if it is otherwise turned off by Platform External Account Settings
  ENABLE_BANK_ACCOUNT_VERIFICATION_UPLOAD_FLOW:
    'enable_bank_account_verification_upload_flow' as const, // allows the bank account document upload to be a separate composition in remediation flow
  ENABLE_BANK_ACCOUNT_VERIFICATION_NAME_UPDATE:
    'enable_bank_account_verification_name_update' as const, // allows the ability to change name fields in-line in the UI for resolving Bank Acocunt Verification
  ENABLE_GA_EMBEDDED_FINANCE_FEATURES:
    'enable_ga_embedded_finance_features' as const, // Enables features intended for GA of the existing embedded finance components
  ENABLE_EC_REPLACE_CARD_UI: 'enable_ec_replace_card_ui' as const, // enables the replace card button + modal in the CardView component
  ENABLE_EMBEDDED_ONBOARDING_RESPONSIVE_DESIGN:
    'enable_embedded_onboarding_responsive_design' as const,
  ENABLE_PAYOUT_SCHEDULE_ONBOARDING_ARK_V2_EMBEDDED_FLAG:
    'enable_payout_schedule_onboarding_ark_v2_embedded_flag' as const,
};

export const FLAGS = {
  ...SERVER_CONTROLLED_FLAGS,
  ...URL_FLAGS,
  ...ComponentOverrideFlag,
};

// This type exists to ensure that FLAGS has only flags as values
export const doNotImportEnsureObjectOnlyContainsFlags: Record<string, Flag> =
  FLAGS;
