import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const appSettingsDynamicImport = async () => {
  const dynamicImport = await import(
    /* webpackChunkName: "appSettings" */ '../../../components/Apps/AppSettings'
  );
  return dynamicImport;
};

export const appAppSettingsParameter = 'app';
export const platformUserIdAppSettingsParameter = 'user-id';

const appSettingsParams = [
  appAppSettingsParameter,
  platformUserIdAppSettingsParameter,
] as const;
type AppSettingsParams = (typeof appSettingsParams)[number];
export type AppSettingsHtmlParams = Partial<Record<AppSettingsParams, string>>;

export const AppSettingsConfig: ConnectElementConfig = {
  Component: appSettingsDynamicImport,
  htmlAttributes: {
    app: {
      // TODO: Missing API review, however this is going to be exposed to private beta platforms
      isInternal: false,
    },
    'user-id': {
      // TODO: Missing API review, however this is going to be exposed to private beta platforms
      isInternal: false,
    },
  },
  enabledKey: 'app_onboarding',
  enabledOverrideFlag: ComponentOverrideFlag.app_onboarding,
  releaseState: 'private_beta',
  teamOwner: 'app_platform',
  description:
    'Renders a Stripe app - more specifically, renders the "embedded.settings" viewport on the configured stripe app id.',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/embedded-app-settings',
  },
};
