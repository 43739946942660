import {
  ConnectElementImportKeys,
  CONNECT_ELEMENT_IMPORTS,
} from '../ConnectJSInterface/ConnectElementList';
import {getStronglyTypedKeys} from '../../utils/getStronglyTypedKeys';

export const connectElementTags = getStronglyTypedKeys(CONNECT_ELEMENT_IMPORTS);

export const isConnectElementTag = (
  name: string,
): name is ConnectElementImportKeys => {
  return (
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !!name && connectElementTags.includes(name as ConnectElementImportKeys)
  );
};

export type ConnectElementTagName =
  | 'payments'
  | 'payouts'
  | 'payouts-list'
  | 'balances'
  | 'payment-details'
  | 'account-onboarding'
  | 'payment-method-settings'
  | 'account-management'
  | 'notification-banner'
  | 'instant-payouts'
  | 'issuing-card'
  | 'issuing-cards-list'
  | 'documents'
  | 'tax-settings'
  | 'tax-registrations';

export const componentNameMapping: Record<
  ConnectElementTagName,
  ConnectElementImportKeys
> = {
  payments: 'stripe-connect-payments',
  payouts: 'stripe-connect-payouts',
  documents: 'stripe-connect-documents',
  balances: 'stripe-connect-balances',
  'payouts-list': 'stripe-connect-payouts-list',
  'payment-details': 'stripe-connect-payment-details',
  'account-onboarding': 'stripe-connect-account-onboarding',
  'payment-method-settings': 'stripe-connect-payment-method-settings',
  'account-management': 'stripe-connect-account-management',
  'notification-banner': 'stripe-connect-notification-banner',
  'instant-payouts': 'stripe-connect-instant-payouts',
  'issuing-card': 'stripe-connect-issuing-card',
  'issuing-cards-list': 'stripe-connect-issuing-cards-list',
  'tax-settings': 'stripe-connect-tax-settings',
  'tax-registrations': 'stripe-connect-tax-registrations',
};
