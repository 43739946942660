import buildParams from 'src/internal/analytics/buildParams';
import {defaultRpcClient} from 'src/internal/rpc/defaultRpc';
import {v4 as uuid} from 'uuid';

import type {
  IAnalytics,
  ImperativeAnalytics,
} from 'src/internal/analytics/types';

type AnalyticsMethodName = keyof IAnalytics;

const rpcMethodMap = {
  action: 'analyticsAction',
  link: 'analyticsLink',
  modal: 'analyticsModal',
  track: 'analyticsTrack',
  viewed: 'analyticsViewed',
} as const;

const method = <TMethod extends AnalyticsMethodName>(
  analyticsMethodName: TMethod,
): ImperativeAnalytics[TMethod] => {
  return (config, eventName, parameters): void => {
    if (!config || config.analytics?.disabled) {
      return;
    }

    // This dual Reporting config is just temporary.
    const currentParams = config._tmp_dualReporting
      ? {...parameters!, _tdr_id: uuid()}
      : parameters;

    const injectedMethod =
      config._internal_observability?.analytics?.[analyticsMethodName];

    if (injectedMethod) {
      injectedMethod(config, eventName, buildParams(config, currentParams));

      if (!config._tmp_dualReporting) {
        return;
      }
    }

    const rpcClient = config.rpc ?? defaultRpcClient;
    const rpcMethod = rpcMethodMap[analyticsMethodName];

    const eventNameWithPotentialDualReporting = config._tmp_dualReporting
      ? `_tdr.${eventName}`
      : eventName;

    rpcClient.call(
      rpcMethod,
      config,
      eventNameWithPotentialDualReporting,
      currentParams,
    );
  };
};

/**
 * Used for tracking user interactions events such as button clicks.
 *
 * Before using this method, make sure that a `viewed` call has been made,
 * and if applicable, that a `modal` call has been made if the action is
 * taking place inside of a modal.
 *
 * Parameters passed to this method will be merged with parameters passed to
 * the `init` method.
 */
export const action = method('action');

/**
 * Used for tracking link events such as links leaving the current scope of the domain.
 *
 * Parameters passed to this method will be merged with parameters passed to
 * the `init` method.
 */
export const link = method('link');

/**
 * Used for tracking modal open events.
 *
 * Before using this method, make sure that a `viewed` call has been made,
 * since this is important contextual information.
 *
 * Parameters passed to this method will be merged with parameters passed to
 * the `init` method.
 */
export const modal = method('modal');

/**
 * Used for tracking generic events. This method should be used as a last
 * resort when none of the other tracking methods fit your use case.
 *
 * Parameters passed to this method will be merged with parameters passed to
 * the `init` method.
 */
export const track = method('track');

/**
 * Used for tracking page views or history changes. Calls to this method
 * should be made when a new page is loaded, or when history changes.
 *
 * Parameters passed to this method will be merged with parameters passed to
 * the `init` method.
 */
export const viewed = method('viewed');
