import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {CURRENCY_FILTER_HTML_ATTRIBUTE} from '../HtmlAttributes';

const earningsChartParams = [
  CURRENCY_FILTER_HTML_ATTRIBUTE,
  'chart-aspect-ratio',
] as const;

type EarningsChartParams = (typeof earningsChartParams)[number];
export type EarningsChartHtmlParams = Partial<
  Record<EarningsChartParams, string>
>;

export const EarningsChartConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "earningsChartConfig" */ '../../../components/Earnings/Earnings'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  teamOwner: 'accounts_ui',
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    [CURRENCY_FILTER_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
    'chart-aspect-ratio': {
      isInternal: true,
    },
  },
  description: 'A chart that displays the earnings for the given account.',
};
