import {FetchApiError, FetchErrorResponse} from '../fetch';
import {getStatusRange} from './getStatusRange';

export const fileReadErrorCode = 'LOCAL_BROWSER_FILE_READ_ERROR';
export class FileReadError extends Error {
  internalError: any;

  constructor(message: string, internalError: any) {
    super(message);
    this.internalError = internalError;
    this.name = 'FetchError';
  }
}

export class NativeFetchError extends Error {
  internalError: any;

  constructor(message: string, internalError: any) {
    super(message);
    this.name = 'NativeFetchError';
    this.internalError = internalError;
  }
}

export class LoadStripeJSError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'LoadStripeJSError';
  }
}

/* eslint-disable camelcase */
export type GQLErrorResponse = {
  message?: string;
  error?: {
    code?: string;
  } & {
    [key: string]: any;
  };
  two_factor_options?: any;
  two_factor_primary_method?: any;
  [key: string]: any;
};

export type NormalizedGQLErrorResponse = GQLErrorResponse & {
  http_status: number;
  request_id?: string | null | undefined;
  asserts?: boolean;
};
/* eslint-enable camelcase */

export const getStripeOrGraphQLErrorMetadata = (
  error: unknown,
): IApiErrorMetadata => {
  const normalizedGraphQLError: NormalizedGQLErrorResponse | undefined =
    isNormalizedErrorResponse(error) ? error : undefined;

  if (normalizedGraphQLError) {
    return getGraphQLErrorMetadata(normalizedGraphQLError);
  }

  const fetchError: FetchApiError | undefined =
    error instanceof FetchApiError ? error : undefined;
  if (fetchError) {
    return getFetchApiErrorMetadata(fetchError.error);
  }

  const nativeFetchError: NativeFetchError | undefined =
    error instanceof NativeFetchError ? error : undefined;
  if (nativeFetchError) {
    return getNativeFetchErrorMetadata(nativeFetchError);
  }

  const stripeJSError: LoadStripeJSError | undefined =
    error instanceof LoadStripeJSError ? error : undefined;
  if (stripeJSError) {
    return getLoadStripeJSErrorMetadata(stripeJSError);
  }

  const fileReadError: FileReadError | undefined =
    error instanceof FileReadError ? error : undefined;
  if (fileReadError) {
    return getFileReadErrorMetadata(fileReadError);
  }

  const regularError: Error | undefined =
    error instanceof Error ? error : undefined;
  if (regularError) {
    // This means we aren't dealing with a FetchError or GraphQLError. This should not happen,
    // however if it does we want to extract as much information as possible from the error to be able to diagnose it
    return {
      stripeErrorRawType: `NONAPIERROR:${regularError.name}`,
      stripeErrorMessage: regularError.message,
      stripeErrorCode: regularError.message,
      stripeRequestId: 'NONAPIERROR',
    };
  }

  // At this point we don't know what type of object we are dealing with - we do our best to extract as much information as possible
  let jsonStringifiedObject = '';
  try {
    jsonStringifiedObject = JSON.stringify(error);
  } catch (e) {
    jsonStringifiedObject = 'JSON.stringify failed';
  }
  return {
    stripeErrorRawType: `UNKNOWNOBJECTTYPE:${typeof error}`,
    stripeErrorCode: `JSONSTRING:${jsonStringifiedObject}`,
    stripeErrorMessage: `JSONSTRING:${jsonStringifiedObject}`,
    stripeRequestId: 'UNKNOWNOBJECTTYPE',
  };
};

export interface IApiErrorMetadata {
  stripeErrorRawType?: string;
  stripeStatusCode?: number;
  stripeStatusCodeRange?: string;
  stripeErrorMessage?: string;
  stripeErrorCode?: string;
  stripeRequestId?: string;
}

const isNormalizedErrorResponse = (
  error: unknown,
): error is NormalizedGQLErrorResponse => {
  return !!error && typeof error === 'object' && 'http_status' in error;
};

const getGraphQLErrorMetadata = (
  graphQLError: NormalizedGQLErrorResponse,
): IApiErrorMetadata => {
  const errorMetadata: IApiErrorMetadata = {
    stripeStatusCode: graphQLError.http_status,
    stripeStatusCodeRange: getStatusRange(graphQLError.http_status),
    stripeErrorMessage: graphQLError.message,
    stripeRequestId: graphQLError.request_id ?? undefined,
    stripeErrorCode:
      graphQLError.code ||
      graphQLError.error?.code ||
      graphQLError.message_code,
    stripeErrorRawType: graphQLError.type ?? undefined,
  };

  return errorMetadata;
};

const getFetchApiErrorMetadata = (
  error: FetchErrorResponse,
): IApiErrorMetadata => {
  const errorMetadata: IApiErrorMetadata = {
    stripeStatusCode: error.httpStatus,
    stripeStatusCodeRange: getStatusRange(error.httpStatus),
    stripeErrorMessage: error.message,
    stripeRequestId: error.requestId,
    stripeErrorCode: error.code || error.messageCode,
    stripeErrorRawType: error.type,
  };
  return errorMetadata;
};

const getNativeFetchErrorMetadata = (
  error: NativeFetchError,
): IApiErrorMetadata => {
  const errorMetadata: IApiErrorMetadata = {
    stripeStatusCode: 0, // 0 will mark this as a network error. All native fetch errors are network errors
    stripeStatusCodeRange: 'NONE',
    stripeErrorMessage: `${error.message}. Internal error: ${error.internalError?.message}`,
    stripeRequestId: 'NONE',
    stripeErrorCode: 'UNKNOWN',
    stripeErrorRawType: `${error.name}. Internal error:${error.internalError.name}`,
  };
  return errorMetadata;
};

const getLoadStripeJSErrorMetadata = (
  error: LoadStripeJSError,
): IApiErrorMetadata => {
  const errorMetadata: IApiErrorMetadata = {
    stripeStatusCode: 0, // 0 will mark this as a network error. All failures to load Stripe.js errors are network errors
    stripeStatusCodeRange: 'NONE',
    stripeErrorMessage: `Internal error: ${error.message}`,
    stripeRequestId: 'NONE',
    stripeErrorCode: 'UNKNOWN',
    stripeErrorRawType: `${error.name}. Internal error:${error.message}`,
  };
  return errorMetadata;
};

const getFileReadErrorMetadata = (error: FileReadError): IApiErrorMetadata => {
  const errorMetadata: IApiErrorMetadata = {
    stripeStatusCode: undefined,
    stripeStatusCodeRange: 'NONE',
    stripeRequestId: 'NONE',
    stripeErrorCode: fileReadErrorCode,
    stripeErrorRawType: fileReadErrorCode,
    stripeErrorMessage: `${error.message} : ${
      error.internalError?.message ?? ''
    }`,
  };
  return errorMetadata;
};
