import {message} from '@sail/intl';
import {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {CURRENCY_FILTER_HTML_ATTRIBUTE} from '../HtmlAttributes';

const messages = {
  errorText: message({
    id: 'connectEmbed.payouts.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your payouts information cannot be loaded here.',
    description: 'Message shown when an unhandled payouts error is caught',
  }),
};

const payoutsComponentParams = [CURRENCY_FILTER_HTML_ATTRIBUTE] as const;
type PayoutsComponentParams = (typeof payoutsComponentParams)[number];
export type PayoutsComponentHtmlParams = Partial<
  Record<PayoutsComponentParams, string>
>;

export const PayoutsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "payoutsExperience" */ '../../../components/Payouts/PayoutsV2/PayoutsV2'
    ),
  messageOverrides: messages,
  releaseState: 'ga',
  enabledKey: 'payouts',
  enabledOverrideFlag: ComponentOverrideFlag.payouts,
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    [CURRENCY_FILTER_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  teamOwner: 'accounts_ui',
  description:
    'Displays the list of payouts, allows for manual payouts, instant payouts, and management of payout schedule.',
  docSitePath: '/connect/supported-embedded-components/payouts',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-payouts-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-payouts-iframe-rc',
  },
};
