import {ObservabilityConfig} from '@sail/observability';
import {mapValues} from 'lodash';
import {SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import {ReportError} from '@stripe-internal/connect-embedded-lib/dist/context/errorReporting/ErrorTypes';
import {
  ConnectElementImportKeys,
  sentryTeams,
} from './ConnectJSInterface/ConnectElementList';
import {
  Metrics,
  IMetricsEvent,
  getCurrentPageViewId,
} from '../data-layer-client/Metrics';
import {isAnalyticsDisabled} from '../data-layer-frame/isAnalyticsDisabled';
import {submerchantAnalyticsParams} from '../data-layer-client/submerchantAnalyticsParams';
import {EmbeddedError, PrimitiveRecord} from '../utils/errorHandling';
import {getTeamForComponent} from '../utils/getTeamForComponent';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {IFrameMessenger} from '../data-layer-client/FrameMessenger';
import {MetaOptions} from './ConnectJSInterface/InitAndUpdateOptionsTypes';
import {buildAnalyticsSender} from './buildAnalyticsSender';

export function getObservabilityConfig({
  connectElement,
  frameMessenger,
  metaOptions,
  reportError,
}: {
  frameMessenger: IFrameMessenger;
  // connectElement is marked as optional because we're sending metrics in src/connect/connector.ts,
  // where connect element isn't existing yet
  connectElement?: ConnectElementImportKeys;
  reportError?: ReportError;
  metaOptions?: MetaOptions;
}): ObservabilityConfig {
  const project = getTeamForComponent(connectElement);
  const {serviceEnvironment} = getCurrentScriptUrlContext();

  const hostEnv = serviceEnvironment === 'prod' ? 'prod' : 'qa';
  const projects = mapValues(sentryTeams, (team) => team[hostEnv]);

  // WARNING! Keep in sync with  `src/connect/setupRender.ts` and the
  // `baseParams` object.
  const baseParams = {
    connectElement: connectElement ?? 'unknown',
    hostApp: metaOptions?.hostApp ?? 'platform',
    teamName: project,
  };

  // We append connect element to tags for all metrics
  const metricsSender = (metrics: IMetricsEvent) => {
    frameMessenger.sendMetrics({...metrics, ...baseParams});
  };

  const dataLayerMetrics = new Metrics(metricsSender);

  const analyticsSender = buildAnalyticsSender(
    frameMessenger.sendAnalytics,
    baseParams,
  );

  const defaultReportError: ReportError = (
    error,
    tags,
    extras,
    owner,
    level,
  ) => {
    frameMessenger.sendErrorReport({
      error,
      connectElement,
      tags,
      extra: extras,
      owner,
      level: level ?? 'error',
    });
  };

  const reportSender = reportError ?? defaultReportError;

  const trackIntlError = (error: any) => {
    if (error.message?.includes('sdk/intl')) {
      // We only want to track errors that are related to the sdk/intl package
      // [@sail/intl] Missing message ...
      // [@sail/intl] Error formatting message ...
      analyticsSender?.track('submerchant_surfaces_intl_error', {
        error_message: error.message,
        error_stack: error?.stack,
      });
    }
  };

  return {
    project,
    service: 'connect-js',
    pageViewToken: getCurrentPageViewId(),
    release: process.env.COMMIT_HASH,
    analytics: {
      disabled: isAnalyticsDisabled(),
      disableCookies: true,
      parameters: {
        version: process.env.COMMIT_HASH,
        ...baseParams,
      },
      parametersAllowList: Object.keys(submerchantAnalyticsParams),
      // Report analytics to both r.stripe.com and t.stripe.com
      useLegacyEndpoint: true,
    },
    errors: {
      disabled: isAnalyticsDisabled(),
      projects,
      tags: {
        type: EmbeddedError.Error,
        ...baseParams,
      },
      extras: baseParams,
      _internal_injected_methods_only: true,
    },
    metrics: {
      disabled: isAnalyticsDisabled(),
      parameters: {
        host_env: hostEnv,
        ...baseParams,
      },
    },
    rpc: frameMessenger.getObservabilityRpc(),
    _tmp_dualReporting: true,
    _internal_observability: {
      metrics: {
        increment: (_, metric, tags) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          dataLayerMetrics?.increment(metric, tags as any | undefined);
        },
        gauge: (_, metric, measurements, tags) => {
          dataLayerMetrics?._groupTiming(
            metric,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            measurements as any,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            tags as any | undefined,
          );
        },
        set: () => undefined,
      },
      analytics: {
        action: (_, id, params) => {
          analyticsSender?.action(id, params);
        },
        modal: (_, id, params) => {
          analyticsSender?.modal(id, params);
        },
        link: (_, id, params) => {
          analyticsSender?.link(id, params);
        },
        viewed: (_, id, params) => {
          analyticsSender?.viewed(id, params);
        },
        track: (_, eventName, params) => {
          analyticsSender?.track(eventName, params);
        },
      },
      errors: {
        error: (error, scope) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const extras = scope?.extras as PrimitiveRecord | undefined;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const project = scope?.project as SentryTeamName | undefined;
          reportSender?.(error, scope?.tags, extras, project, 'error');
          trackIntlError(error);
        },
        warning: (error, scope) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const extras = scope?.extras as PrimitiveRecord | undefined;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const project = scope?.project as SentryTeamName | undefined;
          reportSender?.(error, scope?.tags, extras, project, 'warning');
          trackIntlError(error);
        },
      },
    },
  };
}
