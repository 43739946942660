import {
  DEVBOX_PREFIX,
  getTargetEnvironment,
  ServiceEnvironment,
} from '../utils/getTargetEnvironment';

export type ServiceName =
  | 'api'
  | 'files'
  | 'site'
  | 'connect'
  | 'admin'
  | 'dashboard'
  | 'stripejs'
  | 'accounts';

export const STRIPE_API_VERSION = '2020-08-27';

export const serviceEndpoints: Record<
  ServiceName,
  Record<ServiceEnvironment, string>
> = {
  api: {
    devbox: `${DEVBOX_PREFIX}-api-mydev.dev.stripe.me`,
    qa: 'qa-api.stripe.com',
    preprod: 'preprod-api.stripe.com',
    prod: 'api.stripe.com',
  },
  files: {
    devbox: `${DEVBOX_PREFIX}-upload-mydev.dev.stripe.me`,
    qa: 'qa-files.stripe.com',
    preprod: 'preprod-files.stripe.com',
    prod: 'files.stripe.com',
  },
  site: {
    devbox: `${DEVBOX_PREFIX}-site-mydev.dev.stripe.me`,
    qa: 'qa.stripe.com',
    preprod: 'preprod.stripe.com',
    prod: 'stripe.com',
  },
  connect: {
    devbox: `${DEVBOX_PREFIX}-manage-mydev.dev.stripe.me`,
    qa: 'qa-connect.stripe.com',
    preprod: 'preprod-connect.stripe.com',
    prod: 'connect.stripe.com',
  },
  admin: {
    devbox: `${DEVBOX_PREFIX}-admin-mydev.dev.stripe.me`,
    qa: 'qa-dashboard.stripe.com',
    // Admin doesn't have preprod
    preprod: 'admin.qa.corp.stripe.com',
    prod: 'admin.corp.stripe.com',
  },
  dashboard: {
    devbox: `${DEVBOX_PREFIX}-manage-mydev.dev.stripe.me/`,
    qa: 'qa-dashboard.stripe.com',
    preprod: 'preprod-dashboard.stripe.com',
    prod: 'dashboard.stripe.com',
  },
  stripejs: {
    devbox: `${DEVBOX_PREFIX}-stripejs-mydev.dev.stripe.me/v3/`,
    qa: 'qa-js.stripe.com/v3/',
    preprod: 'preprod-js.stripe.com/v3/',
    prod: 'js.stripe.com/v3/',
  },
  accounts: {
    devbox: `${DEVBOX_PREFIX}-recipient_payouts_hosted_onboarding-mydev.dev.stripe.me`,
    qa: 'qa-accounts.stripe.com',
    preprod: 'preprod-accounts.stripe.com',
    prod: 'accounts.stripe.com',
  },
};

const buildStripeHostOverride = (
  service: ServiceName,
  targetEnvironment: ServiceEnvironment,
): string => {
  const environmentToUse = getTargetEnvironment(targetEnvironment);

  return serviceEndpoints[service][environmentToUse];
};

export const getStripeApiHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('api', targetEnvironment);
};

export const getStripeFilesHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('files', targetEnvironment);
};

export const getStripeSiteHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('site', targetEnvironment);
};

export const getStripeConnectHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('connect', targetEnvironment);
};

export const getStripeAdminHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('admin', targetEnvironment);
};

export const getStripeDashboardHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('dashboard', targetEnvironment);
};

export const getStripeJsHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('stripejs', targetEnvironment);
};

export const getStripeAccountsHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('accounts', targetEnvironment);
};
