import CounterWithAreaCount from 'src/internal/platformMetrics/aggregators/CounterWithAreaCount';

export const safeParams = [
  'org_analytics',
  'org_experiments',
  'org_experiments_hook',
  'org_flags',
  'org_merchant',
  'org_merchant_hook',
  'org_polyjuice',
  'org_polyjuice_hook',
  'org_other',
  'org_user_preferences',
] as const;

type SafeParams = (typeof safeParams)[number];

export default new CounterWithAreaCount<SafeParams>(
  'frontend.platform-ui.org-globals-usage-count',
);
