import type {PrefillOptions, ArkSectionName} from '@stripe-internal/ark';
import type {OnboardingStep} from '@stripe-internal/ark-lib';
import {ConnectElementObservableValues} from './ConnectElementObservableValues';
import {TYPE_TO_FILTER_MESSAGES} from '../components/Transactions/filter/transactionTypes';
import {AccountManagementSectionType} from '../components/Account/AccountManagement/AccountManagementForm/types';

export interface IssuingCardsListDefaultFilters {
  created?: {
    gte: number;
    lte: number;
  };
  type?: 'virtual' | 'physical' | 'all';
  cardholder?: string;
}

export interface TransactionsListDefaultFilters {
  type?: keyof typeof TYPE_TO_FILTER_MESSAGES;
}

// https://docs.google.com/document/d/1KohI_dTXnSYk2jTGj2sa7yaS7BT6bs18Ih_sR2tw3IU/edit#heading=h.z6qtovk62wrs
export interface CollectionOptions {
  fields: 'currently_due' | 'eventually_due';
  futureRequirements?: 'omit' | 'include';
}

export interface MobileBannerOptions {
  minimized?: boolean;
  renderInNav?: boolean;
}

// Note: Supplemental objects should not contain any non-serializable fields (i.e. functions), since these fields get
// serialized to the UI layer directly via JSON.stringify in a window message
export interface IConnectElementSupplementalObject {
  // Internal
  debugUtilObjectInternalDoNotUse?: {
    prop1: string;
    prop2: {
      subprop1: number;
      subprop2: boolean;
    };
    prop3: string[];
  };
  transactionsListDefaultFilters?: TransactionsListDefaultFilters;
  arkV2DisabledSections?: ArkSectionName[];
  disableBankConnections?: boolean;
  isDirectSettings?: boolean;
  prefillOptions?: PrefillOptions;
  // Used in AccountOnboarding to control which composition and/or
  // section to render, allowing consumers to implement navigation.
  step?: OnboardingStep;
  sectionView?: AccountManagementSectionType;

  // Used in HostedDashboardNotificationBanner to control the display of the mobile banner
  mobileBannerOptions?: MobileBannerOptions;

  // API reviewed
  // Beta
  issuingCardsListDefaultFilters?: IssuingCardsListDefaultFilters;

  appData?: Record<string, string>;

  // GA
  collectionOptions?: CollectionOptions;
}

export type SupplementalObjectKey = keyof IConnectElementSupplementalObject;

export type SupplementalObjectValue =
  IConnectElementSupplementalObject[SupplementalObjectKey];

export class ConnectElementSupplementalObjects extends ConnectElementObservableValues<IConnectElementSupplementalObject> {}
