import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {MAX_CARD_IMAGE_WIDTH} from '../../../components/Issuing/dimensions';

export const defaultCardParameter = 'default-card' as const;
export const cardArtFileLinkParameter = 'card-art-file-link' as const;
export const cardSwitchingParameter = 'card-switching' as const;

const issuingCardParams = [
  defaultCardParameter,
  cardArtFileLinkParameter,
  cardSwitchingParameter,
] as const;

type IssuingCardParams = (typeof issuingCardParams)[number];
export type IssuingCardHtmlParams = Partial<Record<IssuingCardParams, string>>;

export const IssuingCardConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "issuingCard" */
      '../../../components/Issuing/IssuingCard'
    ),
  enabledKey: 'issuing_card', // Flag that will determine if the custom element should be rendered
  htmlAttributes: {
    [defaultCardParameter]: {
      isInternal: false,
    },
    [cardArtFileLinkParameter]: {
      isInternal: false,
    },
    [cardSwitchingParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setDefaultCard: {
      type: 'AttributeSettingMethod',
      attribute: defaultCardParameter,
      isInternal: false,
      description: 'Sets the default card to display',
    },
    setCardArtFileLink: {
      type: 'AttributeSettingMethod',
      attribute: cardArtFileLinkParameter,
      isInternal: false,
      description:
        'Sets the card art file link to display. Only works for platforms that have been allowlisted.',
    },
    setCardSwitching: {
      type: 'AttributeSettingMethod',
      attribute: cardSwitchingParameter,
      isInternal: false,
      description: 'Controls displaying the card switching UI.',
    },
    setFetchEphemeralKey: {
      type: 'SupplementalFunction',
      objectToSet: 'fetchEphemeralKey',
      isInternal: false,
      description:
        'Sets the function to fetch an ephemeral key for the card to display PCI specific information for the card (like the entirety of the card number).',
    },
  },
  releaseState: 'private_beta',
  teamOwner: 'ef_surfaces',
  maxWidth: MAX_CARD_IMAGE_WIDTH,
  description:
    'Displays a card in a Card UI - allows for switching to other cards, activation and deactivation of cards.',
  docSitePath: '/connect/supported-embedded-components/issuing-card',
  syntheticTestInfo: {
    testUrl: 'https://amp.corp.stripe.com/synthetics/us-west-2/cards-details',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-issuing-card-i-rc',
  },
};
