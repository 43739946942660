import {message} from '@sail/intl';
import {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.lpmSettings.errorBoundary.text',
    description: 'Message shown when an unhandled LPM settings error is caught',
    defaultMessage:
      'Your money is secure, but your payment method settings cannot be loaded here.',
  }),
};

export const paymentMethodConfigurationParameter =
  'payment-method-configuration' as const;

export const PaymentMethodSettingsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "lpmSettingsExperience" */
      '../../../components/PaymentMethodSettings/PaymentMethodSettings'
    ),
  htmlAttributes: {
    [paymentMethodConfigurationParameter]: {
      // API reviewed for private beta
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setPaymentMethodConfiguration: {
      type: 'AttributeSettingMethod',
      attribute: paymentMethodConfigurationParameter,
      // API reviewed for private beta
      isInternal: false,
      description:
        'Sets a payment method configuration ID to render in the compponent',
    },
  },
  messageOverrides: messages,
  releaseState: 'private_beta',
  enabledKey: 'payment_method_settings',
  enabledOverrideFlag: ComponentOverrideFlag.payment_method_settings,
  requiresAuthentication: true,
  teamOwner: 'lpm_adoption',
  description:
    'Displays the list of local payment methods enabled/disabled for the current account, and allows tweaking these methods.',
  docSitePath: '/connect/supported-embedded-components/payment-method-settings',
  syntheticTestInfo: {
    testUrl: 'https://amp.corp.stripe.com/synthetics/us-west-2/sus-lpms-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-lpms-iframe-rc',
  },
};
