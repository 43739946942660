import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const CapitalOverviewConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "capitalOverviewExperience" */
      '../../../components/Capital/CapitalOverview'
    ),
  enabledKey: 'capital_overview',
  enabledOverrideFlag: ComponentOverrideFlag.capital_overview,
  releaseState: 'private_beta',
  teamOwner: 'capital_for_platforms',
  description:
    'Serves as a mini Capital dashboard that allows the user to access their current and past Capital loan information at all times.',
};
