import {message} from '@sail/intl';
import {ConnectElementConfig} from '../ConnectElementList';

export const onCloseParameter = 'onClose' as const;

const messages = {
  errorText: message({
    id: 'connectEmbed.accountManagement.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your account information cannot be loaded here.',
    description: 'Message shown when an unhandled account error is caught',
  }),
};

export const AccountManagementFormConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "accountManagementForm" */ '../../../components/Account/AccountManagement/AccountManagementForm/AccountManagementForm'
    ),
  messageOverrides: messages,
  enabledKey: 'internal',
  releaseState: 'internal',
  requiresOnboarding: true,
  requiresAuthentication: true,
  teamOwner: 'accounts_ui',
  htmlEvents: ['accountmanagementformclose'],
  customHtmlElementMethods: {
    setSectionView: {
      type: 'SupplementalObject',
      objectToSet: 'sectionView',
      isInternal: true,
      description:
        'Sets the current section and subview of account management to render. Currently, only the company, person, support, and external account sections are supported.',
    },
    setOnClose: {
      type: 'EventListenerMethod',
      event: 'accountmanagementformclose',
      isInternal: true,
      description: 'This is called when the subview is closed.',
    },
    setDisableBankConnections: {
      type: 'SupplementalObject',
      objectToSet: 'disableBankConnections',
      isInternal: true,
      description:
        'Disables the ability to add linked accounts in the external account form.',
    },
  },
  description:
    'Displays a form for the specified section of Account management',
};
