import {ServiceEnvironment} from './getTargetEnvironment';
import {isDevEnvironment} from './isDevEnvironment';

export interface IScriptUrlContext {
  origin: string;
  absoluteFolderPath: string;
  serviceEnvironment: ServiceEnvironment;
}

export const getServiceEnvironmentFromConnectJsUrl = (
  url: URL,
): ServiceEnvironment => {
  // Example URLs
  // DEVBOX:
  // https://localhost:3001/v0.1/connect.js
  // https://$DEVBOX-USER*-suswebpack.tunnel.stripe.me
  // QA:
  // https://qa-b.stripecdn.com/submerchant-surfaces-statics-srv/assets/v0.1/connect.js
  // https://qa-js.stripe.com/v0.1/connect.js
  // PREPROD:
  // https://preprod-b.stripecdn.com/submerchant-surfaces-statics-srv/assets/v0.1/connect.js
  // https://preprod-connect-js.stripe.com/v0.1/connect.js
  // PROD:
  // https://b.stripecdn.com/submerchant-surfaces-statics-srv/assets/v0.1/connect.js
  // https://connect-js.stripe.com/v0.1/connect.js
  const origin = url.host.toLowerCase();

  if (
    origin.startsWith('localhost') ||
    origin.startsWith('127.0.0.1') ||
    (process.env.USER && origin.includes(String(process.env.USER)))
  ) {
    return 'devbox';
  } else if (origin.startsWith('qa-')) {
    return 'qa';
  } else if (origin.startsWith('preprod-') || origin.startsWith('edge-')) {
    return 'preprod';
  } else {
    return 'prod';
  }
};

let result: IScriptUrlContext | undefined;
export const computeCurrentScriptUrlContext = (
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  currentScript: HTMLOrSVGScriptElement | null = document.currentScript,
  isDevEnv: boolean = isDevEnvironment,
) => {
  if (result && !currentScript) {
    return;
  }

  const currentScriptUrl = currentScript?.getAttribute('src');
  if (!currentScriptUrl) {
    return;
  }

  // Example: connect JS is https://qa-b.stripecdn.com/submerchant-surfaces-statics-srv/assets/connect.js
  // then origin is https://qa-b.stripecdn.com and folder path is https://qa-b.stripecdn.com/submerchant-surfaces-statics-srv/assets/

  const url = new URL(
    currentScriptUrl,
    // If we are in an iframe, a relative script path is used, i.e. /ui_layer.js, hence we need to use the window.location.href as a base URL.
    // Also, in the sample platform, we use a relative script path, i.e. /v0.1/connect.js
    // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
    window.location.href,
  );
  const folderPathComponents = url.pathname.split('/');
  folderPathComponents.pop(); // Remove the connect.js filename
  // In we are running in iframes, the script we are executing is not the connect.js script, but the iframe's script
  // e.g. https://b.stripecdn.submerchant-surfaces-statics-srv/assets/ui_layer.js or https://connect-js.stripe.com/ui_layer.js
  // Therefore, there is no connect.js version to remove from the folder path - we also don't want to remove the /assets path
  // if it exists. Hence, we add a check for the version path here

  const isLastPathSegmentVersion =
    folderPathComponents[folderPathComponents.length - 1].match(/^v\d+\.\d+$/i);

  if (isLastPathSegmentVersion) {
    folderPathComponents.pop(); // Remove the connect.js version if present
  }

  const folderPath = folderPathComponents.join('/');
  let {origin} = url;
  if (isDevEnv) {
    // When running locally, we intentionally replace the data layer URL with 127.0.0.1 to force different origins between the
    // host and the data layer. This helps to catch issues stemming from differing origins between the host and data layer earlier
    // in the development process (makes local dev ~= production)
    origin = url.origin.replace('localhost', '127.0.0.1');
  }

  result = {
    origin,
    absoluteFolderPath: origin + folderPath,
    serviceEnvironment: getServiceEnvironmentFromConnectJsUrl(url),
  };
};
// eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
computeCurrentScriptUrlContext(document.currentScript);

export const getCurrentScriptUrlContext = () => {
  if (!result) {
    throw new Error('Script location was not computed');
  }
  return result;
};
