import {
  AnalyticsRecord,
  IAnalyticsEvent,
  IAnalyticsSender,
} from '../data-layer-frame/AnalyticsTypes';

export type BaseConnectElementParams = {
  connectElement?: string;
  hostApp?: string;
  teamName?: string;
};
const appendConnectElementToParams = (
  params?: AnalyticsRecord,
  baseParams?: BaseConnectElementParams,
) => {
  if (!baseParams) {
    return params;
  }

  /**
   * If ErrorOwnershipContext is set, we can use the `owner` value
   * to override the teamName set in the baseParams
   */
  const teamName = params?.teamName || baseParams.teamName;

  return {
    ...params,
    ...baseParams,
    ...(teamName ? {teamName} : {}),
  };
};

export function buildAnalyticsSender(
  sendAnalytics: (event: IAnalyticsEvent) => void,
  baseParams?: BaseConnectElementParams,
): IAnalyticsSender {
  return {
    action: (id: string, params?: AnalyticsRecord) =>
      sendAnalytics({
        id,
        params: appendConnectElementToParams(params, baseParams),
        type: 'action',
      }),
    link: (id: string, params?: AnalyticsRecord) =>
      sendAnalytics({
        id,
        params: appendConnectElementToParams(params, baseParams),
        type: 'link',
      }),
    modal: (id: string, params?: AnalyticsRecord) =>
      sendAnalytics({
        id,
        params: appendConnectElementToParams(params, baseParams),
        type: 'modal',
      }),

    viewed: (id: string, params?: AnalyticsRecord) =>
      sendAnalytics({
        id,
        params: appendConnectElementToParams(params, baseParams),
        type: 'viewed',
      }),
    track: (id: string, params?: AnalyticsRecord) =>
      sendAnalytics({
        id,
        params: appendConnectElementToParams(params, baseParams),
        type: 'track',
      }),
  };
}
