import {SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import * as Sentry from '@sentry/browser';

export type PrimitiveRecord = Record<string, string | number | boolean>;

export const EmbeddedError = {
  ReactError: 'reactError',
  ApiError: 'apiError',
  Error: 'error',
  Assertion: 'assertion',
} as const;

export type ReportErrorTags = PrimitiveRecord & {
  type: (typeof EmbeddedError)[keyof typeof EmbeddedError];
};

export type ReportError = (
  error: Error,
  tags?: ReportErrorTags,
  extra?: PrimitiveRecord,
  owner?: SentryTeamName,
  level?: Sentry.SeverityLevel,
) => void;

export const getFirstSafeElement = (obj: any): string => {
  if (Array.isArray(obj)) {
    return makeSafe(obj[0]);
  } else {
    return makeSafe(obj);
  }
};

// Force the incoming value to be a string
export const makeSafe = (obj: any): string => {
  if (typeof obj === 'string') {
    return obj;
  } else {
    // TODO Make a more generic version of this - SUS-184
    return JSON.stringify(obj);
  }
};

// Expected error handling for react-query
// We check for a string[] on the query.meta?.expectedErrors property
export const isExpectedErrorsType = (obj: any): obj is string[] => {
  return (
    !!obj && Array.isArray(obj) && obj.every((el) => typeof el === 'string')
  );
};
