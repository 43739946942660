import * as Sentry from '@sentry/browser';
import {IAnalytics} from '@sail/observability';
import {isDevEnvironment} from '../../utils/isDevEnvironment';
import {getCurrentScriptUrlContext} from '../../utils/getCurrentScriptUrlContext';
import {
  CONNECT_ELEMENT_IMPORTS,
  ConnectElementImportKeys,
  accountsUIDSN,
  sentryTeams,
} from '../../connect/ConnectJSInterface/ConnectElementList';
import {configureScopeCallback} from '../../data-layer-frame/sentry/ErrorReporter';
import {isAnalyticsDisabled} from '../../data-layer-frame/isAnalyticsDisabled';
import {getDevLogger} from '../../utils/getLogger';
import {analytics} from '../../data-layer-frame/analytics';

export type IframeLayer =
  | 'ui-layer'
  | 'accessory-layer'
  | 'stripejs-layer'
  | 'pdf-preview-layer';

type IframeErrorReporterOptions = {
  iframeLayer: IframeLayer;
  connectElement: ConnectElementImportKeys;
};

const {serviceEnvironment} = getCurrentScriptUrlContext();

const defaultOptions: Sentry.BrowserOptions = {
  integrations: [new Sentry.Integrations.UserAgent()],
  environment: isDevEnvironment ? 'localdev' : 'production',
  release: process.env.COMMIT_HASH,
};

// This returns a function that will send an error report to Sentry and an analytics event reporting the iframe specific error
export const createSentryErrorAndAnalyticsSender = (
  sendSentryError: (error: Error) => void,
  analytics: IAnalytics,
  connectElement: ConnectElementImportKeys,
) => {
  const {teamOwner} = CONNECT_ELEMENT_IMPORTS[connectElement];

  const sendSentryErrorAndAnalytics = (
    error: Error,
    iframeLayer: IframeLayer,
  ) => {
    if (!isAnalyticsDisabled()) {
      sendSentryError(error);

      // This is used to send analytics events if errors are thrown in the iframe JS that would affect loading the iframes
      analytics.track(`submerchant_surfaces_${iframeLayer}_error`, {
        error: error.message,
        connectElement,
        teamName: teamOwner,
      });
    }
  };

  return sendSentryErrorAndAnalytics;
};

export class IframeErrorReporter {
  private connectElement: ConnectElementImportKeys;

  private iframeLayer: IframeLayer;

  private sentryEnvironment: 'prod' | 'qa' =
    serviceEnvironment === 'prod' && !isDevEnvironment ? 'prod' : 'qa';

  private sentryHub: Sentry.Hub;

  public captureIframeException: (error: Error) => void;

  constructor({connectElement, iframeLayer}: IframeErrorReporterOptions) {
    this.iframeLayer = iframeLayer;
    this.connectElement = connectElement;
    const {teamOwner} = CONNECT_ELEMENT_IMPORTS[this.connectElement];

    // This is the Sentry instance tied to the Accounts UI Sentry DSN. This will be used to capture exceptions that
    // are related to errors in the iframe loading process.
    this.sentryHub = new Sentry.Hub(
      new Sentry.BrowserClient({
        ...defaultOptions,
        dsn: accountsUIDSN[this.sentryEnvironment],
        ignoreErrors: [
          // https://github.com/WICG/resize-observer/issues/38 - This is a common issue with ResizeObserver but it is a benign error
          // Sail Next mutes these errors too - https://livegrep.corp.stripe.com/view/stripe-internal/pay-server/frontend/sail-next/packages/sdk/src/observability/internal/errors/constants/filters.tsx#L67
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications.',
        ],
      }),
    );
    this.sentryHub.configureScope((scope) => {
      scope.setTag('iframeLayer', this.iframeLayer);
      scope.setTag('connectElement', this.connectElement);
      return configureScopeCallback(scope, [
        this.iframeLayer,
        this.connectElement,
      ]);
    });

    this.captureIframeException = (error: Error) =>
      createSentryErrorAndAnalyticsSender(
        (error: Error) =>
          this.sentryHub.run((currentHub) =>
            currentHub.captureException(error),
          ),
        analytics,
        this.connectElement,
      )(error, this.iframeLayer);

    // Sentry instance for the embedded component's owner. This will capture all errors in the iframe javascript.
    if (!isAnalyticsDisabled()) {
      let sentryTeam = sentryTeams[teamOwner];

      if (!sentryTeam) {
        getDevLogger().warn(
          `The team ${teamOwner} does not have a Sentry DSN. Using default team.`,
        );
        sentryTeam = accountsUIDSN;
      }

      Sentry.init({
        ...defaultOptions,
        dsn: sentryTeam[this.sentryEnvironment],
        ignoreErrors: [
          // https://github.com/WICG/resize-observer/issues/38 - This is a common issue with ResizeObserver but it is a benign error
          // Sail Next mutes these errors too - https://livegrep.corp.stripe.com/view/stripe-internal/pay-server/frontend/sail-next/packages/sdk/src/observability/internal/errors/constants/filters.tsx#L67
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications.',
        ],
        // https://jira.corp.stripe.com/browse/RUN_OBS-83660 - https://github.com/getsentry/sentry-javascript/issues/4404
        // The client reports feature is not supported by our self hosted Sentry instance
        sendClientReports: false,
      });
      Sentry.configureScope((scope) => {
        scope.setTag('iframeLayer', this.iframeLayer);
        scope.setTag('connectElement', this.connectElement);
        return configureScopeCallback(scope, [
          this.iframeLayer,
          this.connectElement,
        ]);
      });
    }
  }
}

let iframeErrorReporter: IframeErrorReporter;
export const getIframeErrorReporter = (): IframeErrorReporter => {
  return iframeErrorReporter;
};
export const initIframeErrorReporter = (
  options: IframeErrorReporterOptions,
) => {
  iframeErrorReporter = new IframeErrorReporter(options);
  return iframeErrorReporter;
};
