import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {ConnectElementConfig} from '../ConnectElementList';

export const CapitalFinancingApplicationConfig: ConnectElementConfig = {
  Component: () =>
    import('../../../components/Capital/CapitalFinancingApplication'),
  enabledKey: 'capital_financing_application',
  enabledOverrideFlag: ComponentOverrideFlag.capital_financing_application,
  htmlEvents: ['onapplicationsubmitted'],
  customHtmlElementMethods: {
    setOnApplicationSubmitted: {
      type: 'EventListenerMethod',
      event: 'onapplicationsubmitted',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function for when a financing application is submitted.',
    },
  },
  releaseState: 'private_beta',
  teamOwner: 'capital_for_platforms',
  description:
    'Capital offer application that is available in the CapitalFinancingPromotion component but can also be standalone.',
};
