export const runningInIframeParamName = 'stripe_internal_running_in_iframe';

// This function returns true if we are in the data layer or ui layer iframe,
// where things behave differently (i.e. we use hash params instead of query params)
export const isRunningInIframe = (): boolean => {
  const url = new URL(window.location.href);
  const hash = url.hash.replace('#', '');
  const hashParams = new URLSearchParams(hash);
  const runningInIframe = hashParams.get(runningInIframeParamName);
  return runningInIframe === 'true';
};
