import {LayerType} from '@sail/global-state';
import {getCurrentScriptUrlContext} from '../../utils/getCurrentScriptUrlContext';
import {ConnectElementImportKeys} from '../../connect/ConnectJSInterface/ConnectElementList';
import {embeddedComponentParamName} from '../buildUILayerFrame';

export const buildAccessoryLayerFrame = async (
  frameId: string,
  layerType: LayerType,
  overlayZIndex: number,
  connectElement: ConnectElementImportKeys,
): Promise<HTMLIFrameElement> => {
  const scriptUrlContext = getCurrentScriptUrlContext();
  const accessoryUrl = new URL(
    // eslint-disable-next-line camelcase
    `${scriptUrlContext.absoluteFolderPath}/accessory_layer_${__webpack_hash__}.html`,
  );

  // Serialize the search params as a hash on the accessory layer source URL.
  // The layer type is set in the query string so the iframe code can determine what layer type it is.
  const searchParams = new URLSearchParams();
  searchParams.set('layer_type', layerType);
  searchParams.set(embeddedComponentParamName, connectElement);
  accessoryUrl.hash = searchParams.toString();

  const iframe = document.createElement('iframe');
  iframe.name = frameId;

  // Allows Gelato to request camera access
  iframe.setAttribute('allow', 'camera');

  // hide the iframe until first sizing
  iframe.style.position = 'absolute';
  iframe.style.top = '-10000px';
  iframe.style.left = '-10000px';

  iframe.style.height = layerType === 'overlay' ? '100%' : '0px';
  iframe.style.width = '100%';
  iframe.src = accessoryUrl.toString();

  // iframe style resets
  iframe.style.background = 'transparent';
  iframe.style.border = 'none';
  iframe.style.borderRadius = '0px';
  iframe.style.borderWidth = '0px';
  iframe.style.boxSizing = 'content-box'; // This one is important for positioning and sizing
  iframe.style.cursor = 'auto';
  iframe.style.margin = '0px';
  iframe.style.maxHeight = 'none';
  iframe.style.maxWidth = 'none';
  iframe.style.minHeight = 'none';
  iframe.style.minWidth = 'none';
  iframe.style.opacity = '1';
  iframe.style.outline = 'none';
  iframe.style.padding = '0px';
  iframe.style.pointerEvents = 'auto';
  iframe.style.transform = 'none';
  iframe.style.transition = 'none';
  iframe.style.userSelect = 'auto';
  iframe.style.verticalAlign = 'baseline';
  iframe.style.visibility = 'visible';

  // Hide scrollbar
  iframe.style.overflow = 'hidden';
  iframe.scrolling = 'no';

  iframe.setAttribute(
    'data-testid',
    `stripe-connect-accessory-layer-${connectElement}`,
  );

  iframe.style.zIndex = overlayZIndex.toString();

  window.document.body.append(iframe);

  const originalBodyOverflowX = window.document.body.style.overflowX;
  const originalBodyOverflowY = window.document.body.style.overflowY;
  let overrodeBodyOverflowX = false;
  let overrodeBodyOverflowY = false;

  if (layerType === 'overlay') {
    const computedBodyOverflowX = window.getComputedStyle(
      window.document.body,
    ).overflowX;
    const computedBodyOverflowY = window.getComputedStyle(
      window.document.body,
    ).overflowY;
    if (
      computedBodyOverflowY === 'scroll' ||
      computedBodyOverflowY === 'auto'
    ) {
      overrodeBodyOverflowY = true;
      window.document.body.style.overflowY = 'hidden';
    }
    if (
      computedBodyOverflowX === 'scroll' ||
      computedBodyOverflowX === 'auto'
    ) {
      overrodeBodyOverflowX = true;
      window.document.body.style.overflowX = 'hidden';
    }
  }

  if (overrodeBodyOverflowX || overrodeBodyOverflowY) {
    const observer = new MutationObserver(function (records) {
      records.forEach(() => {
        const openOverlayIframes = Array.from(
          window.document.querySelectorAll('iframe'),
        ).filter((iframe) => {
          if (!iframe.src) {
            return false;
          }
          try {
            const url = new URL(iframe.src);
            const searchParams = new URLSearchParams(
              url.hash.replace(/^#/, ''),
            );
            return searchParams.get('layer_type') === 'overlay';
          } catch (e) {
            // Sometimes the urls may not be valid, but if they aren't, then they're not ours.
            return false;
          }
        });
        if (openOverlayIframes.length === 0) {
          if (overrodeBodyOverflowX) {
            window.document.body.style.overflowX = originalBodyOverflowX;
          }
          if (overrodeBodyOverflowY) {
            window.document.body.style.overflowY = originalBodyOverflowY;
          }
          observer.disconnect();
        }
      });
    });
    observer.observe(document.body, {childList: true});
  }

  return new Promise((resolve, reject) => {
    iframe.onload = () => {
      resolve(iframe);
    };
    iframe.onerror = (error) => {
      reject(error);
    };
  });
};
