import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const cardArtFileLinkParameter = 'card-art-file-link' as const;

const issuingCardsListParams = [cardArtFileLinkParameter] as const;

type IssuingCardsListParams = (typeof issuingCardsListParams)[number];
export type IssuingCardsListHtmlParams = Partial<
  Record<IssuingCardsListParams, string>
>;

export const IssuingCardsListConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "issuingCardsList" */
      '../../../components/Issuing/IssuingCardsList'
    ),
  enabledKey: 'issuing_cards_list',
  htmlAttributes: {
    [cardArtFileLinkParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setCardArtFileLink: {
      type: 'AttributeSettingMethod',
      attribute: cardArtFileLinkParameter,
      isInternal: false,
      description:
        'Sets the card art file link to display. Only works for platforms that have been allowlisted.',
    },
    setFetchEphemeralKey: {
      type: 'SupplementalFunction',
      objectToSet: 'fetchEphemeralKey',
      isInternal: false,
      description:
        'Sets the function to fetch an ephemeral key for the card to display PCI specific information for the card (like the entirety of the card number).',
    },
    setDefaultFilters: {
      type: 'SupplementalObject',
      objectToSet: 'issuingCardsListDefaultFilters',
      isInternal: false,
      description:
        'Sets the default filters to apply to the cards list. Includes created, type and cardholder.',
    },
  },
  releaseState: 'private_beta',
  teamOwner: 'ef_surfaces',
  description: 'Displays the list of cards on the given Connected Account.',
  docSitePath: '/connect/supported-embedded-components/issuing-cards-list',
  syntheticTestInfo: {
    testUrl: 'https://amp.corp.stripe.com/synthetics/us-west-2/cards-list',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-issuing-list-i-rc',
  },
};
