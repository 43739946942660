import {Locale} from '@sail/intl';

declare global {
  interface SailIntlLocale {
    'bg-BG': true;
    'cs-CZ': true;
    'da-DK': true;
    'de-DE': true;
    'el-GR': true;
    'en-US': true;
    'en-GB': true;
    'en-AU': true;
    'en-IE': true;
    'en-IN': true;
    'en-NZ': true;
    'en-SG': true;
    'es-ES': true;
    'es-419': true;
    'et-EE': true;
    'fi-FI': true;
    'fil-PH': true;
    'fr-FR': true;
    'fr-CA': true;
    'hr-HR': true;
    'hu-HU': true;
    'id-ID': true;
    'it-IT': true;
    'ja-JP': true;
    'ko-KR': true;
    'lt-LT': true;
    'lv-LV': true;
    'ms-MY': true;
    'mt-MT': true;
    'nb-NO': true;
    'nl-NL': true;
    'pl-PL': true;
    'pt-PT': true;
    'pt-BR': true;
    'ro-RO': true;
    'sk-SK': true;
    'sl-SI': true;
    'sv-SE': true;
    'th-TH': true;
    'tr-TR': true;
    'vi-VN': true;
    'zh-Hans': true;
    'zh-Hant-HK': true;
    'zh-Hant-TW': true;
  }
}

export const supportedLocales: Locale[] = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-US',
  'en-GB',
  'en-SG',
  'en-AU',
  'en-IE',
  'en-IN',
  'en-NZ',
  'es-ES',
  'es-419',
  'et-EE',
  'fi-FI',
  'fil-PH',
  'fr-FR',
  'fr-CA',
  'hr-HR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lt-LT',
  'lv-LV',
  'ms-MY',
  'mt-MT',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'pt-PT',
  'pt-BR',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'vi-VN',
  'zh-Hans',
  'zh-Hant-HK',
  'zh-Hant-TW',
];

export const DEFAULT_LOCALE = 'en-US';

const fallbackMap: Record<string, Locale> = {
  'es-MX': 'es-419',
  'es-AR': 'es-419',
  'es-BR': 'es-419',
  'zh-Hant-HK': 'zh-Hans',
  'zh-Hant-TW': 'zh-Hans',
};

type LocaleSource = 'parameter' | 'browser';

type LocaleMatch = {
  attemptedLocale: string;
  matchedLocale: Locale;
  match: 'match' | 'partialMatch' | 'mismatch' | 'fallback' | 'default';
  source: LocaleSource;
};

export const calculateSupportedLocale = (
  preferredLocale?: string,
): LocaleMatch => {
  const preferredLocales = getPreferredLocales(preferredLocale);

  // eslint-disable-next-line no-restricted-syntax
  for (const localeCandidate of preferredLocales) {
    const source =
      localeCandidate === preferredLocale ? 'parameter' : 'browser';

    const supportedLocale = supportedLocales.find(
      (l) =>
        l.toLowerCase() === localeCandidate.replace('_', '-').toLowerCase(),
    );
    if (supportedLocale) {
      return {
        attemptedLocale: localeCandidate,
        matchedLocale: supportedLocale,
        source,
        match: 'match',
      };
    }

    const similarMatch = findSimilarLocale(localeCandidate, source);
    if (similarMatch) {
      return similarMatch;
    }
  }

  return {
    attemptedLocale: preferredLocales[0] || '',
    matchedLocale: DEFAULT_LOCALE,
    source: preferredLocale ? 'parameter' : 'browser',
    match: !preferredLocales[0] ? 'default' : 'mismatch',
  };
};

export const findSimilarLocale = (
  baseLocale: string,
  source: LocaleSource,
): LocaleMatch | null => {
  // Don't bother if the base locale is the default
  if (baseLocale === DEFAULT_LOCALE) {
    return null;
  }

  // If we have a fallback for this locale, use that
  if (fallbackMap[baseLocale]) {
    return {
      attemptedLocale: baseLocale,
      matchedLocale: fallbackMap[baseLocale],
      match: 'fallback',
      source,
    };
  }

  const localeParts = baseLocale.split('-');

  while (localeParts.length) {
    // remove locale modifiers until we find something similar to the baseLocale
    const candidate = localeParts.join('-');
    const available = supportedLocales.find(
      (l) =>
        l !== baseLocale && l.toLowerCase().startsWith(candidate.toLowerCase()),
    );

    if (available) {
      return {
        attemptedLocale: baseLocale,
        matchedLocale: available,
        match: 'partialMatch',
        source,
      };
    }

    localeParts.pop();
  }

  // or return nothing if we couldn't find anything similar
  return null;
};

const getPreferredLocales = (preferredLocale?: string): string[] => {
  // If there is a locale specified, respect that
  if (preferredLocale) {
    return [preferredLocale];
  } else {
    return Array.from(navigator.languages);
  }
};
