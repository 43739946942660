import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const DebugUILibraryConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "debugUILibrary" */ '../../../components/Debug/UILibrary/UILibrary'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  teamOwner: 'accounts_ui',
  description:
    'A number of sample components (textboxes, buttons, badges, dropdowns, tabs, popovers, dialogs, text...) that allow for previewing the impact of theming API changes. Used in the Stripe doc site.',
};
