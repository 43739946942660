import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {ConnectElementConfig} from '../ConnectElementList';

export const appInstallDynamicImport = async () => {
  const dynamicImport = await import(
    /* webpackChunkName: "appInstall" */ '../../../components/Apps/AppInstall'
  );
  return dynamicImport;
};

export const appAppInstallParameter = 'app';
export const appInstallTestingParameter = 'test';

const appInstallFetchedEvent = 'appinstallfetched';
const appInstallCompletedEvent = 'appinstallcompleted';

const appInstallParams = [
  appAppInstallParameter,
  appInstallTestingParameter,
] as const;
type AppInstallParams = (typeof appInstallParams)[number];
export type AppInstallHtmlParams = Partial<Record<AppInstallParams, string>>;

export const AppInstallConfig: ConnectElementConfig = {
  Component: appInstallDynamicImport,
  // To deprecate, using SupplementalObjects intead
  htmlAttributes: {
    [appAppInstallParameter]: {
      isInternal: false,
    },
    test: {
      isInternal: false,
    },
  },
  htmlEvents: [appInstallFetchedEvent, appInstallCompletedEvent],
  customHtmlElementMethods: {
    setApp: {
      type: 'AttributeSettingMethod',
      attribute: appAppInstallParameter,
      isInternal: false,
      description: 'Sets the app id for the app install button',
    },
    setOnAppInstallStateFetched: {
      type: 'EventListenerMethod',
      event: appInstallFetchedEvent,
      isInternal: false,
      description:
        'Sets the event listener for when the app install state is fetched',
    },
    setOnAppInstallStateChanged: {
      type: 'EventListenerMethod',
      event: appInstallCompletedEvent,
      isInternal: false,
      description:
        'Sets the event listener for when the app install state changes (i.e. when the user installs or uninstalls the app)',
    },
  },
  enabledKey: 'app_install',
  enabledOverrideFlag: ComponentOverrideFlag.app_install,
  disableMinWidth: true,
  releaseState: 'private_beta',
  teamOwner: 'app_platform',
  description:
    'An install button for a stripe app. Allows for installing/uninstalling.',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/embedded-app-install',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/afp-form-interaction',
  },
  docSitePath: '/connect/supported-embedded-components/app-install',
};
