import {SentryDSN, SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import {isConnectElementTag} from '../connect/utils/connectElementTags';
import {
  CONNECT_ELEMENT_IMPORTS,
  sentryTeams,
} from '../connect/ConnectJSInterface/ConnectElementList';
import {getDevLogger} from './getLogger';

export const getDSNForComponent = (
  component?: string,
  owner?: SentryTeamName,
): SentryDSN => {
  if (owner) {
    const team = sentryTeams[owner];
    if (team) {
      return team;
    }

    getDevLogger().warn(
      `Invalid owner provided when obtaining sentry DSN ${owner}. Ensure this entry exists in the sentry DSN configurations.`,
    );
  }
  const team = getTeamForComponent(component);
  return sentryTeams[team];
};

export const getTeamForComponent = (component?: string): SentryTeamName => {
  if (!component || !isConnectElementTag(component)) {
    return 'accounts_ui'; // We default to connected accounts UI as infra owners of embedded components
  }
  return CONNECT_ELEMENT_IMPORTS[component].teamOwner || 'accounts_ui';
};

export const isValidSentryTeamName = (
  owner: unknown,
): owner is SentryTeamName => {
  if (!owner) return false;
  return (
    typeof owner === 'string' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (Object.keys(sentryTeams) as string[]).includes(owner)
  );
};
