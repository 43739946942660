import type {CustomFontOptions} from '@stripe-internal/connect-embedded-lib';
import {AppearanceOptions} from '@stripe-internal/embedded-theming';
import {Metrics} from '@sail/observability';
import {computeCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {getTestmodeLogger} from '../utils/getLogger';
import {Connect} from './Connect';
import {connectElementTags} from './utils/connectElementTags';
import {wrapComponentAsCustomElement} from './utils/wrapComponentAsCustomElement';
import {
  getBaseTimingMetrics,
  markConnectTiming,
  onNetworkIdle,
} from '../utils/telemetry/performanceMetrics';
import {livemodeFromPublishableKey} from '../utils/livemodeFromPublishableKey';
import {MetaOptions} from './ConnectJSInterface/InitAndUpdateOptionsTypes';
import {IConnectInstancePublicInterface} from './ConnectJSInterface/ConnectInstancePublicInterface';
import {IPlatformSpecifiedConnectJsOptions} from './ConnectJsOptions';
import {getObservabilityConfig} from './getObservabilityConfig';

// We keep track of the first Connect instance to ensure backwards compatibility
// E.g. if a embedded component is added to the DOM, not using the `create` method,
// we use this Connect instance to render it.
let defaultConnector: Connect | null = null;
export const getDefaultConnector = (): Connect => {
  return defaultConnector!;
};

// The actual class around the Connect instance that we will expose publicly.
class ConnectInstanceWrapper implements IConnectInstancePublicInterface {
  private connect: Connect;

  constructor(connect: Connect) {
    this.connect = connect;
  }

  public getCurrentConnectJSOptions():
    | IPlatformSpecifiedConnectJsOptions
    | undefined {
    return this.connect.getCurrentConnectJSOptions();
  }

  public getConnectJSAppearanceOrder(): string[] | undefined {
    return this.connect.getConnectJSAppearanceOrder();
  }

  public getCurrent(): IPlatformSpecifiedConnectJsOptions | undefined {
    return this.connect.getCurrentConnectJSOptions();
  }

  public setReactSdkAnalytics(version: string): void {
    this.connect.setReactSdkAnalytics(version);
  }

  public create(tagName: string): HTMLElement | null {
    return this.connect.create(tagName);
  }

  public update(options: unknown): void {
    this.connect.update(options);
  }

  public async logout() {
    await this.connect.logout();
  }
}

// This will only run when window.StripeConnect.init is called
// See https://trailhead.corp.stripe.com/docs/connect-integration-guide/connect-embedded-components-tech#component-hierarchy for more details.
const initialConnectJSSetup = (connector: Connect, publishableKey: string) => {
  // Set the first Connect instance
  if (!defaultConnector) {
    defaultConnector = connector;

    // Register the custom elements
    const testmodeLogger = getTestmodeLogger(
      !livemodeFromPublishableKey(publishableKey, true),
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const tagName of connectElementTags) {
      const customElementIsDefinedOnWindow = window.customElements.get(tagName);
      if (customElementIsDefinedOnWindow) {
        testmodeLogger.error(
          `<${tagName}> was unexpectedly already registered by something other than Connect.js on the page.`,
        );
        continue; // eslint-disable-line no-continue
      }

      const customElement = wrapComponentAsCustomElement(tagName);
      window.customElements.define(tagName, customElement);
    }
  }
};

export function setup(
  publishableKey: string,
  clientSecret?: string,
  fetchClientSecret?: () => Promise<string>,
  metaOptions?: MetaOptions,
  appearance?: AppearanceOptions,
  locale?: string,
  refreshSecretCallback?: () => Promise<string>,
  fonts?: CustomFontOptions,
): ConnectInstanceWrapper {
  computeCurrentScriptUrlContext(document.currentScript);

  const connector = new Connect(
    publishableKey,
    clientSecret,
    metaOptions,
    appearance,
    locale,
    refreshSecretCallback,
    fetchClientSecret,
    fonts,
  );

  initialConnectJSSetup(connector, publishableKey);

  // We don't block on auth to initialize connect elements
  connector.deferredAuthPromise.promise.then((_response) => {
    markConnectTiming('authEnd');
    onNetworkIdle(() => {
      const timings = getBaseTimingMetrics();

      if (!timings) {
        return;
      }

      const observabilityConfig = getObservabilityConfig({
        frameMessenger: connector.frameMessenger,
        metaOptions,
      });

      const metrics = new Metrics(observabilityConfig);

      metrics.gauge('submerchant_surfaces_base_timings', timings);
    });
  });

  return new ConnectInstanceWrapper(connector);
}
