import {
  FetchApiError,
  FetchErrorResponse,
} from '@stripe-internal/connect-embedded-lib';
import {OpenapiPostV1EmbeddedSurfacesInputSurfaceEnum} from '@stripe-internal/data-v1/schema/types';
import {ConnectElementImportKeys} from '../connect/ConnectJSInterface/ConnectElementList';

export const errorMessage = (error: any): string => {
  return error instanceof Error ? error.message : String(error);
};

export const mapConnectElementToAccountEmbeddedSurfaceEnum = (
  element: ConnectElementImportKeys,
) => {
  // This maps the ConnectElementImportKey to the AccountEmbeddedSurface Enum used in lib/connect/embedded/constants.rb
  // Example: stripe-connect-account-management -> account_management,
  // stripe-connect-account-onboarding -> account_onboarding, etc.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return element
    .replace('stripe-connect-', '')
    .replaceAll('-', '_') as OpenapiPostV1EmbeddedSurfacesInputSurfaceEnum;
};

export type SerializedFetchError = {
  errorMessage: string;
  errorResponse: FetchErrorResponse;
};

export const serializeFetchErrorForPostMessage = (
  error: FetchApiError,
): SerializedFetchError => {
  return {
    errorMessage: error.message,
    errorResponse: error.error,
  };
};

export const deseralizeFetchErrorFromPostMessage = (
  error: SerializedFetchError,
): FetchApiError => {
  return new FetchApiError(error.errorMessage, error.errorResponse);
};
