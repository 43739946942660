import {useInteractOutside} from '@sail/ui';
import React from 'react';
import ConnectElementBaseMessageChannel from '../ConnectElementBaseMessageChannel';
import {getAllUILayers} from './frameUtils';

export const useInteractAnywhere = (
  handler: (event: React.SyntheticEvent) => void,
  ownerDocument: Document | undefined | null,
) => {
  const [dummyDiv, setDummyDiv] = React.useState(() => ({
    current: ownerDocument ? ownerDocument.createElement('div') : null,
  }));

  React.useEffect(() => {
    if (ownerDocument && !dummyDiv.current) {
      setDummyDiv({
        current: ownerDocument.createElement('div'),
      });
    }
  }, [dummyDiv, ownerDocument]);

  useInteractOutside({
    ref: dummyDiv,
    onInteractOutside: handler,
    isDisabled: !ownerDocument,
  });
};

/**
 * This component is used in the IframeLayerOverrides for Popovers and Tooltips.
 * It will call the `handler`, i.e. `onClose` function when clicking on any UI layer.
 */
export const OutsideUILayerInteractions = ({
  handler,
  children,
  uiLayers = getAllUILayers(),
}: {
  handler: () => void;
  children: React.ReactNode;
  uiLayers?: Window[];
}) => {
  // We have to recurse to be able to iterate the hook
  const [uiLayer, ...rest] = uiLayers;

  useInteractAnywhere(handler, uiLayer?.document);

  if (rest.length > 0) {
    return (
      <OutsideUILayerInteractions handler={handler} uiLayers={rest}>
        {children}
      </OutsideUILayerInteractions>
    );
  } else {
    return <>{children}</>;
  }
};

/**
 * This component is used in ConnectElementBase, i.e. on the platform frame.
 * It contains all the interactions that require communication with the UI layer,
 * i.e. closing tooltips on scroll and closing popovers/tooltips on clicking anywhere
 * on the platform frame.
 */
export const PlatformInteractions = ({
  children,
  messageChannel,
}: {
  children?: React.ReactNode;
  messageChannel: ConnectElementBaseMessageChannel;
}) => {
  React.useEffect(() => {
    window.addEventListener('scroll', () => messageChannel.scrollOutside(), {
      capture: true,
    });
    return () => {
      window.removeEventListener(
        'scroll',
        () => messageChannel.scrollOutside(),
        {
          capture: true,
        },
      );
    };
  }, [messageChannel]);

  useInteractAnywhere(() => messageChannel.interactOutside(), window.document);

  return <>{children}</>;
};
