import {PLATFORM_METRICS_PARAMETERS} from 'src/internal/platformMetrics/constants';

import type {ErrorEventReport} from 'src/internal/common/types';

const ERROR_EVENT_PARAMETERS: (keyof ErrorEventReport)[] = [
  'service',
  'release',
  'project',
  'error_message',
  'error_handled',
  'error_issue_id',
  'error_issue_url',
];

const SAIL_DATA_PARAMETERS = [
  'component',
  'field_accesses',
  'networkActionId',
  'networkDuration',
  'networkRequestId',
  'networkStatus',
  'networkTraceId',
  'operationDeduplicated',
  'operationName',
  'operationPrefetch',
  'project',
  'query_name',
  'service',
];

const SAIL_INTL_PARAMETERS = ['locale', 'messageKey', 'translationResult'];

export const DEFAULT_PARAMETERS_ALLOW_LIST = [
  ...SAIL_DATA_PARAMETERS,
  ...SAIL_INTL_PARAMETERS,
  ...ERROR_EVENT_PARAMETERS,
  ...PLATFORM_METRICS_PARAMETERS,
];
